.portfolio {
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    .portfolio__heading {
        text-align: center;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 130px;
            height: 2px;
            background: #52b69a;
            left: 30%;
            top: 50%;
            position: absolute;
        }
        &::after {
            content: "";
            display: block;
            width: 130px;
            height: 2px;
            background: #52b69a;
            right: 30%;
            top: 50%;
            position: absolute;
        }
    }
    .portfolio__desc {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: #dddddd;
    }
}
@media only screen and (max-width : 480px) {
    .portfolio {
        height: auto;
        margin-bottom: 20px;
        .portfolio__heading {
            font-size: 20px;
            &:before {
                width: 50px;
                left: 0%;
            }
            &::after {
                width: 50px;
                right: 0%;
            }
        }
        .portfolio__desc {
            padding-top: 40px;
        }
    }
}
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) {
    .portfolio {
        height: auto;
        margin-bottom: 60px;
        .portfolio__heading {
            font-size: 20px;
            &:before {
                width: 50px;
                left: 20%;
            }
            &::after {
                width: 50px;
                right: 20%;
            }
        }
    }
}
