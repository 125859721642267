.app {
    overflow: hidden;
    .app__body {
        padding: 10px 60px;
        .footer {
            text-align: center;
            letter-spacing: 1px;
            padding: 20px;
            svg {
                color: #52b69a;
            }
        }
    }
}
@media only screen and (max-width : 480px) {
    .app {
        .app__body {
            padding: 10px 25px;
            .footer {
                font-size: 15px;
            }
        }
    }
}

// heading 20px
// middle heading  icons 17px
// font 15px
