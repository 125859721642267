.about {
    height: auto;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    .about__heading {
        text-align: center;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 130px;
            height: 2px;
            background: #52b69a;
            left: 30%;
            top: 50%;
            position: absolute;
        }
        &::after {
            content: "";
            display: block;
            width: 130px;
            height: 2px;
            background: #52b69a;
            right: 30%;
            top: 50%;
            position: absolute;
        }
        
    }
    .about__desc {
        color: #dddddd;
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .about__descImg {
            align-self: center;
            -webkit-box-shadow: 10px 9px 26px -6px rgba(0,0,0,1);
            -moz-box-shadow: 10px 9px 26px -6px rgba(0,0,0,1);
            box-shadow: 10px 9px 26px -6px rgba(0,0,0,1);
            img {
                max-height: 350px;
            }
        }
        .about__descContent {
            width: 50%;
            .header {
                color: #52b69a;
                font-size: 20px;
            }
            .desc {
                padding: 20px 0px;
                word-spacing: 2px;
                letter-spacing: 1px;
                line-height: 1.5;
            }
            ul {
                list-style-type: none;
                padding-top: 20px;
                li {
                    line-height: 1.5;
                }
                svg {
                    color: #52b69a;
                    margin-right: 10px;
                }
            }
        }
    }
}
@media only screen and (max-width : 480px) {
    .about {
        height: auto;
        margin-bottom: 60px;
        .about__heading {
            font-size: 20px;
            &:before {
                width: 50px;
                left: 0%;
            }
            &::after {
                width: 50px;
                right: 0%;
            }
        }
        .about__desc {
            flex-direction: column;
            margin-top: 60px;
            .about__descImg {
                align-self: center;
                img {
                    max-height: 180px;
                }
            }
            .about__descContent {
                margin-top: 30px;
                width: 100%;
                .header {
                    font-size: 17px;
                }
                ul {
                    margin-left: -20px;
                    li {
                        svg {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) {
    .about {
        height: auto;
        margin-bottom: 60px;
        .about__heading {
            font-size: 20px;
            &:before {
                width: 50px;
                left: 20%;
            }
            &::after {
                width: 50px;
                right: 20%;
            }
        }
        .about__desc {
            .about__descImg {
                align-self: flex-start;
                box-shadow: none;
                width: 50%;
                img {
                    max-height: 200px;
                }
            }
            .about__descContent {
                width: 50%;
            }
        }
    }
}

