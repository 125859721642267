.contact {
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    .contact__heading {
        text-align: center;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 130px;
            height: 2px;
            background: #52b69a;
            left: 28%;
            top: 50%;
            position: absolute;
        }
        &::after {
            content: "";
            display: block;
            width: 130px;
            height: 2px;
            background: #52b69a;
            right: 28%;
            top: 50%;
            position: absolute;
        }
    }
    .contact__desc {
        margin: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        * {
            margin-bottom: 20px;
        }
        .contact__social {
            display: flex;
            flex-direction: row;
            svg {
                margin-right: 20px;
                cursor: pointer;
                font-size: 20px;
                &:hover {
                    color: #52b69a;
                }
            }
        }
        button {
            cursor: pointer;
            margin-top: 30px;
            padding: 15px 30px;
            text-align: center;
            border: 0.5px solid #52b69a;
            background: transparent;
            color: #52b69a;
            transition: color 0.4s ease-in, background 0.4s ease-in;
            &:hover {
                color: #edf6f9;
                font-weight: 900;
                background: #52b69a;
            }
        }
    }
}
@media only screen and (max-width : 480px) {
    .contact {
        height: auto;
        width: 100%;
        .contact__heading {
            font-size: 20px;
            margin-bottom: 20px;
            &:before {
                width: 50px;
                left: 0%;
            }
            &::after {
                width: 50px;
                right: 0%;
            }
        }
        .contact__desc {
            margin: 0px;
            font-size: 15px;
            line-height: 1.7;
            .contact__social {
                svg {
                    font-size: 17px;
                }
            }
            button {
                padding: 10px 25px;
            }
        }
    }
}
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) {
    .contact {
        height: auto;
        margin-bottom: 60px;
        .contact__heading {
            font-size: 20px;
            &:before {
                width: 50px;
                left: 20%;
            }
            &::after {
                width: 50px;
                right: 20%;
            }
        }
    }
}