.sideNav {
    position: fixed;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 250px;
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #0b1a2f;
    border-radius: 110px;
    -webkit-box-shadow: -4px 3px 25px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -4px 3px 25px 0px rgba(0,0,0,0.75);
    box-shadow: -4px 3px 25px 0px rgba(0,0,0,0.75);
    z-index: 99999;
    svg {
        cursor: pointer;
        font-size: 20px;
    }
}