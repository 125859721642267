.work {
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    .work__heading {
        text-align: center;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 130px;
            height: 2px;
            background: #52b69a;
            left: 28%;
            top: 50%;
            position: absolute;
        }
        &::after {
            content: "";
            display: block;
            width: 130px;
            height: 2px;
            background: #52b69a;
            right: 28%;
            top: 50%;
            position: absolute;
        }
        
    }
    .work__desc {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        .work__timeline {
            width: 100%;
            height: auto;
            max-width: 800px;
            margin: 0 auto;
            position: relative;
            ul {
                list-style: none;
                li {
                    padding: 20px;
                    border: 1px solid #52b69a;
                    color:#dddddd;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .work__content {
                        h1 {
                            font-weight: 500;
                            font-size: 25px;
                            line-height: 30px;
                            margin-bottom: 10px;
                        }
                        p {
                            font-size: 16px;
                            line-height: 30px;
                            font-weight: 300;
                        }
                        .date {
                            font-size: 12px;
                            font-weight: 300;
                            margin-bottom: 10px;
                            letter-spacing: 2px;
                        }
                    }
                    width: 50%;
                    position: relative;
                    margin-bottom: 50px;
                    &:nth-child(odd) {
                        float: left;
                        clear: right;
                        transform: translateX(-30px);
                        border-radius: 20px 0px 20px 20px;
                        &::before {
                            transform: translate(50%, -50%);
                            right: -30px;
                        }
                    }
                    &:nth-child(even) {
                        float: right;
                        clear: left;
                        transform: translateX(30px);
                        border-radius: 0px 20px 20px 20px;
                        &::before {
                            transform: translate(-50%, -50%);
                            left: -30px;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        background-color:#52b69a;
                        top: 0px;
                    }
                }
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 52.5%;
                transform: translateX(-50%);
                width: 2px;
                height: 100%;
                background-color: #52b69a;
            }
        }
    }
}
@media only screen and (max-width : 480px) {
    .work {
        height: auto;
        margin-bottom: 60px;
        .work__heading {
            font-size: 20px;
            &:before {
                width: 50px;
                left: 0%;
            }
            &::after {
                width: 50px;
                right: 0%;
            }
        }
        .work__desc {
            padding-top: 40px;
            margin-left: -40px;
            .work__timeline {
                max-width: 480px;
                ul {
                    display: flex;
                    flex-direction: column;
                    li {
                        padding: 15px;
                        width: 80%;
                        .work__content {
                            h1 {
                                font-size: 20px;
                            }
                            .date {
                                font-size: 15px;
                            }
                            p {
                                font-size: 17px;
                            }
                        }
                        &:nth-child(odd) {
                            float: none;
                            clear: both;
                            margin-left: 15px;
                            transform: translateX(30px);
                            border-radius: 0px 20px 20px 20px;
                            &::before {
                                transform: translate(-50%, -50%);
                                left: -30px;
                            }
                        }
                        &:nth-child(even) {
                            float: none;
                            clear: both;
                            margin-left: 15px;
                        }
                    }
                }
                &::before {
                    left: 55px;
                }
            }
        }
    }
}
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) {
    .work {
        height: auto;
        margin-bottom: 60px;
        .work__heading {
            font-size: 20px;
            &:before {
                width: 50px;
                left: 20%;
            }
            &::after {
                width: 50px;
                right: 20%;
            }
        }
        .work__desc {
            .work__timeline {
                &::before {
                    left: 53%;
                }
            }
        }
    }
}