.home {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 140px;
    .home__content {
        width: 30%;
        p {
            padding: 10px 0px;
            line-height: 1.5;
        }
        .home__contentHeader {
            font-weight: 600;
        }
        .highlight {
            color: #52b69a;
        }
        .home__socialButtons {
            display: flex;
            flex-direction: row;
            svg {
                font-size: 22px;
                margin: 8px 16px 8px 0px;
                cursor: pointer;
                transition: margin-top 0.4s ease-in, color 0.4s ease-in;
                &:hover {
                    margin-top: 2px;
                    color: #52b69a;
                }
            }
        }
        button {
            cursor: pointer;
            margin-top: 30px;
            padding: 15px 30px;
            text-align: center;
            border: 0.5px solid #52b69a;
            background: transparent;
            color: #52b69a;
            transition: color 0.4s ease-in, background 0.4s ease-in;
            &:hover {
                color: #edf6f9;
                font-weight: 900;
                background: #52b69a;
            }
        }
    }
    .home__img {
        justify-self: flex-end;
        padding: 0;
        margin: 0;
        img {
            max-height: 450px;
        }
    }
}

@media only screen and (max-width : 480px) {
    .home {
        height: auto;
        flex-direction: column;
        margin-top: 100px;
        margin-bottom: 60px;
        .home__content {
            width: 100%;
            p {
                padding: 5px 0px;
            }
            .highlight {
                font-size: 17px;
            }
            .home__socialButtons {
                svg {
                    font-size: 17px;
                }
            }
            .home__contentHeader {
                font-weight: 400;
                font-size: 20px;
            }
            button {
                padding: 10px;
            }
        }
        .home__img {
            display: none;
            img {
                display: none;
            }
        }
    }
}
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) {
    .home {
        height: auto;
        margin-top: 140px;
        margin-bottom: 100px;
        .home__content {
            width: 60%;
        }
        .home__img {
            width: 40%;
            margin-right: 20px;
            img {
                max-height: 250px;
            }
        }
    }
}