.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 8vh;
    max-height: 8vh;
    padding: 10px 60px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    .nav__logo {
        text-decoration: none;
        cursor: pointer;
        color: #edf6f9;
        font-size: 22px;
        font-weight: 600;
    }
    .nav__list {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .nav__item {
            .nav__itemLink {
                text-decoration: none;
                padding: 0px 20px;
                cursor: pointer;
                color: #edf6f9;
                &:hover {
                    color: #52b69a;
                }
            }
        }
    }
    .nav__mobileList {
        display: none;
    }
}
.active {
    background: #0b1a2f;
}
@media only screen and (max-width : 480px) {
    .nav {
        padding: 10px 25px;
        .nav__logo {
            font-size: 20px;
        }
        .nav__list {
            display: none;
        }
        .nav__mobileList {
            display: block;
            svg {
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
}
