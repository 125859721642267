.projects {
    width: 70%;
    margin-bottom: 40px;
    border: 1px solid #52b69a;
    border-radius: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    padding: 30px 20px;
    height: auto;
    line-height: 2;
    .header {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        svg {
            font-size: 30px;
        }
    }
    .desc {
        .tech {
            display: flex;
            flex-direction: row;
            .tech__item {
                color: #52b69a;
                margin-right: 15px;
                padding: 2px;
                border-radius: 5px 0px 5px 0px;
            }
        }
        .links {
            display: flex;
            flex-direction: row;
            svg {
                cursor: pointer;
                &:hover {
                    color: #52b69a;
                }
            }
            * {
                margin-right: 20px;
            }
        }
    }
}
@media only screen and (max-width : 480px) {
    .projects {
        width: 95%;
        margin-bottom: 30px;
        line-height: 1.5;
        flex-direction: column;
        padding: 20px 20px;
        .header {
            width: 90%;
            flex-direction: row;
            justify-content: start;
            h2 {
                font-size: 17px;
            }
            svg {
                font-size: 17px;
                margin-right: 10px;
            }
        }
        .desc {
            width: 100%;
            line-height: 2;
            .tech {
                .tech__item {
                    margin-right: 2px;
                }
            }
            .links {
                margin-top: 10px;
            }
        }
    }
}
@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) {
    .projects {
        width: 95%;
    }
}