* {
    border: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Exo 2', sans-serif;
    font-size: 18px;
    letter-spacing: 1px;
    color: #edf6f9;
    background-color: #0b1a2f;
}

button {
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
}

@media only screen and (max-width : 480px) {
    body {
        font-size: 15px;
    }
}


// Light mode

// text color 495057
// text hover color 0077b6
// theme color 0b1a2f

// back cbcfd4


// Dark Mode

// background 0b1a2f
// color f1faee, edf6f9
// light color dddddd
// img bg daf5fa

// othe 52b69a
// oth hover 9ad4c4